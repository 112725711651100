
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Vue from 'vue';
import VueCropper from 'vue-cropperjs';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import MoleculeRangeSlider from '@/components/molecules/range-slider/MoleculeRangeSlider.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import ImageEnum from '@/enums/image/ImageEnum';

export enum CropperViewMode {
  VM0 = 0,
  VM1 = 1,
  VM2 = 2,
  VM3 = 3,
}

export default Vue.extend({
  name: 'AtomAddImage',
  props: {
    viewMode: {
      type: Number,
      default: CropperViewMode.VM3,
      validator: (val: number) => Object.values(CropperViewMode).includes(val),
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AtomSvgIcon,
    AtomButton,
    MoleculeRangeSlider,
    'vue-cropper': VueCropper,
  },
  data: () => ({
    imgSrc: '',
    isCropAreaConfirmed: false,
    ButtonVariant,
  }),
  methods: {
    upload(e) {
      if (e.target.files.length !== 0) {
        if (!this.validateFileType(e.target.files)) {
          toast(this.$bvToast, this.$t('myCars.addCarModal.wrongFileType').toString(), ToastVariants.DANGER);
          return;
        }
        if (this.imgSrc) URL.revokeObjectURL(this.imgSrc);
        this.imgSrc = window.URL.createObjectURL(e.target.files[0]);
      }
    },
    validateFileType(files) {
      const { name } = files[0];
      const fileExtension = name.split('.').pop();
      return (fileExtension === ImageEnum.JPG || fileExtension === ImageEnum.JPEG || fileExtension === ImageEnum.PNG);
    },
    triggerUpload() {
      // @ts-ignore-next-line
      this.$refs.imageFileInput.click();
    },
    zoom(val: number) {
      const { cropper } = this.$refs;
      // @ts-ignore-next-line
      cropper.zoomTo(val);
      // @ts-ignore-next-line
      cropper.setDragMode('move');
    },
    emitCrop(checked: boolean) {
      const { cropper } = this.$refs;
      // @ts-ignore-next-line
      const croppedImage = !checked ? '' : cropper.getCroppedCanvas({ maxWidth: 740 }).toDataURL();
      this.isCropAreaConfirmed = true;
      this.$emit('crop', croppedImage);
    },
    cancel() {
      if (this.imgSrc) URL.revokeObjectURL(this.imgSrc);
      this.imgSrc = '';
      this.$emit('cancel');
    },
  },
});
